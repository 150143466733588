import { FunctionComponent, useEffect, useState } from "react";
import { CarArrivalInfo, CarGeneralInfo, CarPayment } from "./interface";
import { Loader, Flex, Space, Group, NumberInput, FileInput, Select, Button, Text, Textarea } from "@mantine/core";
import { DateInput } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import CENTER_STYLE from "../../../core/CenterStyle";
import CLIENT from "../../../core/client/client";
import { formatDate } from "../../../core/format_date";
import ImagesStats from "../../../core/ImagesStats";
import CarFormProps from "./CarFormProps";
import AddMoreFilesButton from "../../../components/forms/AddMoreFilesButton";
import DeleteAbleImage from "../../../core/DeleteAbleImage";

interface CarArrivalFormProps extends CarFormProps { }

const CarArrivalForm: FunctionComponent<CarArrivalFormProps> = ({ initial, close, setShouldReload }) => {
    const [car, setCar] = useState<CarArrivalInfo>({} as CarArrivalInfo);

    const [loading, setLoading] = useState(true);
    const [didFormChange, setDidFormChange] = useState<boolean>(false);
    const [arrival_images, setArrival_images] = useState<File[]>([]);
    const [finalBill, setFinalBill] = useState<File>();


    console.log(car);

    const DOLLARS = <Text size='xs' pr={10}>دولار امريكي</Text>;
    const RIGHT_SEC_W = 86;

    useEffect(initial === undefined ? () => { } : () => {
        loadCarArrivalInfo(initial!.id!).then(e => {
            if (e == null) {
                //     notifications.show({
                //         color: "red",
                //         title: "حدث خطأ",
                //         message: 'لم تنجح العملية، تحقق من اتصالك بالانترنت',
                //     });
                return;
            }



            setCar(e!);
            setLoading(false);
        });
    }, []);


    return <form
        onChange={(e) => setDidFormChange(true)}
        onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const failed = await updateCarArrivalInfo(car, arrival_images);

            setLoading(false);


            if (failed == null) {
                // todo: integrate notification system
                // notifications.show({
                //     color: "red",
                //     title: "حدث خطأ",
                //     message: "تحقق من المعلومات المرسلة او اتصالك بالانترنت و اعد المحاولة",
                // });
                return;
            }

            setShouldReload(true);


            /// prevents save until further changes 
            setDidFormChange(false)

            // closes ONLY when submit was AN ADDITION POST CALL
            if (initial == undefined) close();
        }}>
        {loading ? <div style={CENTER_STYLE}>
            <Loader />
        </div> : <Flex direction={'column'} gap={'md'}>
            <Space h={'md'} />

            <Group grow>
                <DateInput
                    label='تاريخ الوصول' placeholder='تاريخ الوصول'
                    value={car.date_arrived == undefined ? undefined : new Date(Date.parse(car.date_arrived))}
                    onChange={(e) => setCar({
                        ...car,
                        date_arrived: formatDate(e?.toDateString() ?? ''),
                    })}
                />

                <DateInput
                    label='تاريخ الفتح' placeholder='تاريخ الفتح'
                    value={car.date_opened == undefined ? undefined : new Date(Date.parse(car.date_opened))}
                    onChange={(e) => setCar({
                        ...car,
                        date_opened: formatDate(e?.toDateString() ?? ''),
                    })}
                />
            </Group>


            <ImagesStats amount={car.arrival_images?.length ?? 0} title={"صور الوصول"} >
                {/* ADD IMAGE SELECTION */}

                {
                    (car.arrival_images as any)?.map((e: any) => <DeleteAbleImage
                        deletionId={car.id!}
                        deletionKeyEnum="arrival_images"
                        src={e}

                        onRemoved={
                            (c) =>
                                setCar({
                                    ...car,
                                    arrival_images: (car.arrival_images)?.filter((e) => e.url != c),
                                })
                        }
                    />)
                }

            </ImagesStats>


            {/* // edit add images on edit */}
            {(car?.arrival_images == undefined || car?.arrival_images?.length == 0) ? <FileInput accept="image/png,image/jpeg" clearable multiple value={arrival_images} onChange={setArrival_images}
                label="اختيار صور الوصول"
            /> :

                (initial != undefined && <AddMoreFilesButton
                    id={initial.id!}
                    forField="arrival_images"
                    onClose={(e) => {
                        console.log("GET IMAGES");
                        console.log(e);
                    }}
                />)

            }


            <Group grow>
                <DateInput
                    label='تاريخ استلام السيارة' placeholder='تاريخ استلام السيارة'
                    value={car.date_car_picked_up == undefined ? undefined : new Date(Date.parse(car.date_car_picked_up))}

                    onChange={(e) => setCar({
                        ...car,
                        date_car_picked_up: formatDate(e?.toDateString() ?? ''),
                    })}
                />

                <DateInput
                    label='تاريخ استلام الأوراق' placeholder='تاريخ استلام الأوراق'
                    value={car.date_papers_received == undefined ? undefined : new Date(Date.parse(car.date_papers_received))}

                    onChange={(e) => setCar({
                        ...car,
                        date_papers_received: formatDate(e?.toDateString() ?? ''),
                    })}
                />
            </Group>

            <Select

                data={[
                    { value: 'delivering', label: 'قيــــد التــوصــيـــل' },
                    { value: 'delivered', label: 'تـــم الـوصـــول' },
                    { value: 'received', label: 'تـم التسـلـيـــم' },
                ]}
                label='الحالة' placeholder='الحالة'
                value={
                    car.status === undefined ? undefined : car.status
                }

                styles={
                    {
                        input: {
                            color: car.status === undefined ? undefined : car.status == "delivering" ? "#228BE6" : car.status == "delivered" ? "#69DB7C" : "#2F9E44"
                        }
                    }
                }
                onChange={(_value, e) => {
                    if (e == null) return;
                    setDidFormChange(true);

                    setCar({
                        ...car,
                        status: e.value ?? undefined,
                    })
                }}
            />


            <Textarea
                label='ملاحظات' placeholder='ملاحظات'

                value={car.notes}
                onChange={(e) => setCar({
                    ...car,
                    notes: e.currentTarget.value
                })}

                autosize={true}
                minRows={2}
                maxRows={6}
            />

            <Space h={0.5} />

            <Flex direction={'row'} justify={'space-between'}>
                <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>
                <Button type="submit"
                //</Flex>disabled={!didFormChange}
                >{
                        "حفظ التغييرات"
                    }</Button>

            </Flex>

            <Space h={'md'} />
        </Flex>}
    </form>;
}


async function loadCarArrivalInfo(id: number): Promise<CarArrivalInfo | null> {
    try {
        const c = CLIENT();

        console.warn(id);

        const res = await c.get(`/car/arrival`, {
            params: { id: id }
        });


        console.log(res.data);
        console.log(res.status);

        return res.data.data[0];

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null
    }
}

async function updateCarArrivalInfo(car: CarArrivalInfo, f?: File[]): Promise<any | null> {
    try {
        const c = CLIENT();

        const dd = {

            ...car,
            id: car.id!,
            _method: 'PATCH',
            car_info_id: undefined,
            // implement update arrival images
            arrival_images_files: car.arrival_images == undefined || car.arrival_images as any == "null" || car.arrival_images == null || car.arrival_images?.length == 0 ? f : undefined,
        };

        console.warn(dd);

        const res = await c.postForm(`/car/arrival/${car.id!}?_method=PATCH`, dd);


        console.log(res.data);
        console.log(res.status);

        return res.data;

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null;
    }
}

export default CarArrivalForm;