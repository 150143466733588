import { FunctionComponent, useEffect, useState } from "react";
import { CarGeneralInfo, CarPayment } from "./interface";
import { Loader, Flex, Space, Group, NumberInput, Select, Textarea, Button, Text, FileInput, ActionIcon } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import CENTER_STYLE from "../../../core/CenterStyle";
import CLIENT from "../../../core/client/client";
import { DateInput } from "@mantine/dates";
import { formatDate } from "../../../core/format_date";
import CarFormProps from "./CarFormProps";
import { IconDownload } from "@tabler/icons-react";
import DownloadIcon from "../../../core/DownloadIcon";

interface CarPaymentFormProps extends CarFormProps { }


const CarPaymentForm: FunctionComponent<CarPaymentFormProps> = ({ initial, close, setShouldReload }) => {
    const [car, setCar] = useState<CarPayment>({} as CarPayment);

    const [loading, setLoading] = useState(true);
    const [didFormChange, setDidFormChange] = useState<boolean>(false);
    const [initialBill, setInitialBill] = useState<File | null>();
    const [finalBill, setFinalBill] = useState<File | null>();


    console.log(car);

    const DOLLARS = <Text size='xs' pr={10}>دولار امريكي</Text>;
    const RIGHT_SEC_W = 86;

    useEffect(initial === undefined ? () => { } : () => {
        loadCarShipmentInfo(initial!.id!).then(e => {
            if (e == null) {
                //     notifications.show({
                //         color: "red",
                //         title: "حدث خطأ",
                //         message: 'لم تنجح العملية، تحقق من اتصالك بالانترنت',
                //     });
                return;
            }

            setCar(e!);
            setLoading(false);
        });
    }, []);


    return <form
        onChange={(e) => setDidFormChange(true)}
        onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const failed = await updateCarPaymentInfo(car, initialBill ?? undefined, finalBill ?? undefined);

            setLoading(false);


            if (failed == null) {
                // todo: integrate notification system
                // notifications.show({
                //     color: "red",
                //     title: "حدث خطأ",
                //     message: "تحقق من المعلومات المرسلة او اتصالك بالانترنت و اعد المحاولة",
                // });
                return;
            }

            setShouldReload(true);


            /// prevents save until further changes 
            setDidFormChange(false)

            // closes ONLY when submit was AN ADDITION POST CALL
            if (initial == undefined) close();
        }}>
        {loading ? <div style={CENTER_STYLE}>
            <Loader />
        </div> : <Flex direction={'column'} gap={'md'}>
            <Space h={'md'} />

            <Flex direction={'row'} gap={'md'}>
                <NumberInput
                    flex={3}
                    label='مبلغ السيارة' placeholder='مبلغ السيارة'
                    value={car.car_money}
                    rightSection={DOLLARS}
                    thousandSeparator=","

                    rightSectionWidth={RIGHT_SEC_W}
                    onChange={(e) => setCar({
                        ...car,
                        car_money: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

                <FileInput
                    flex={5}
                    clearable
                    label='الفاتورة الاولية'

                    placeholder={initialBill?.name ?? car.initial_bill_file?.title ?? car.initial_bill_file?.path?.slice(-1)[0] ?? undefined}
                    rightSection={car.initial_bill_file?.url == undefined ? undefined : <DownloadIcon url={car.initial_bill_file.url} />}
                    onChange={(e) => setInitialBill(e)}
                />
            </Flex>

            {/* <NumberInput
                label='' placeholder=''
                value={car.car_shipment}
                rightSection={DOLLARS}
                onChange={(e) => setCar({
                    ...car,
                    car_shipment: typeof e === 'number' ? e : Number.parseFloat(e)
                })}
            /> */}

            <NumberInput
                label='الحوالة' placeholder='الحوالة'
                value={car.transaction}
                thousandSeparator=","
                rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                onChange={(e) => setCar({
                    ...car,
                    transaction: typeof e === 'number' ? e : Number.parseFloat(e)
                })}
            />

            <NumberInput
                label='الفاتورة النهائية'
                placeholder='الفاتورة النهائية'
                value={car.final_bill}
                rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                thousandSeparator=","
                onChange={(e) => setCar({
                    ...car,
                    final_bill: typeof e === 'number' ? e : Number.parseFloat(e)
                })}
            />


            <Group grow>
                <NumberInput
                    label='تسديد العميل' placeholder='تسديد العميل'
                    value={car.client_paid}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                    thousandSeparator=","
                    onChange={(e) => setCar({
                        ...car,
                        client_paid: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <DateInput
                    label='تاريخ تسديد العميل' placeholder='تاريخ تسديد العميل'
                    value={car.client_paid_date == undefined ? undefined : new Date(Date.parse(car.client_paid_date))}
                    onChange={(e) => setCar({
                        ...car,
                        client_paid_date: formatDate(e ?? ""),
                    })}
                />
            </Group>

            <NumberInput
                label='المتبقي' placeholder='المتبقي'
                value={car.money_owed_left}
                rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                thousandSeparator=","
                onChange={(e) => setCar({
                    ...car,
                    money_owed_left: typeof e === 'number' ? e : Number.parseFloat(e)
                })}
            />

            <Flex direction={'row'} gap={'md'} >
                <DateInput
                    flex={3}
                    label='تاريخ تسديد المزاد' placeholder='تاريخ تسديد المزاد'
                    value={car.date_paid_to_action == undefined ? undefined : new Date(Date.parse(car.date_paid_to_action))}

                    onChange={(e) => setCar({
                        ...car,
                        date_paid_to_action: formatDate(e ?? ""),
                    })}
                />

                <Select
                    flex={4}

                    data={[
                        { value: 'paid', label: 'مســـــدد' },
                        { value: 'unpaid', label: 'غير مسدد' },
                        { value: 'canceled', label: 'مـكنــسـلـــة' },
                    ]}
                    label='الحالة' placeholder='الحالة'
                    value={
                        car.status === undefined ? undefined : car.status
                    }

                    styles={
                        {
                            input: {
                                color: car.status === undefined ? undefined : car.status == "unpaid" ? "#F76707" : car.status == 'canceled' ? "#F03E3E" : "#37B24D"
                            }
                        }
                    }
                    onChange={(_value, e) => {
                        if (e == null) return;
                        setDidFormChange(true);

                        setCar({
                            ...car,
                            status: e.value ?? undefined,
                        })
                    }}
                />


            </Flex>

            <FileInput
                clearable
                label='الفاتورة النهائية'
                rightSection={car.final_bill_file?.url == undefined ? undefined : <DownloadIcon url={car.final_bill_file.url} />}

                placeholder={finalBill?.name ?? car.final_bill_file?.title ?? car.final_bill_file?.path?.slice(-1)[0]}
                onChange={(e) => setFinalBill(e)}
            />

            <Group grow>

                <NumberInput
                    label='غرامات تأخير تسديد' placeholder='غرامات تأخير تسديد'
                    value={car.late_payment_fine}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                    thousandSeparator=","


                    onChange={(e) => setCar({
                        ...car,
                        late_payment_fine: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <NumberInput
                    label='غرامات تأخير سحب' placeholder='غرامات تأخير سحب'
                    value={car.late_pull_fine}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}
                    thousandSeparator=","


                    onChange={(e) => setCar({
                        ...car,
                        late_pull_fine: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

            </Group>








            <Space h={0.5} />

            <Flex direction={'row'} justify={'space-between'}>
                <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>
                <Button type="submit"

                //disabled={!didFormChange} 
                >{
                        "حفظ التغييرات"
                    }</Button>

            </Flex>

            <Space h={'md'} />
        </Flex>
        }
    </form >;
}


async function loadCarShipmentInfo(id: number): Promise<CarPayment | null> {
    try {
        const c = CLIENT();

        console.warn(id);

        const res = await c.get(`/car/payments`, {
            params: {
                id: id,
            }
        });


        console.log(res.data);
        console.log(res.status);

        return res.data.data[0];

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null
    }
}

async function updateCarPaymentInfo(car: CarPayment, initial?: File, final?: File): Promise<any | null> {
    try {
        const c = CLIENT();

        const dd = {
            ...car,
            id: car.id!,
            car_info_id: undefined,
            _method: 'PATCH',
            initial_bill_file: initial,
            final_bill_file: final,
        };
        console.warn(dd);

        const res = await c.postForm(`/car/payments/${car.id!}?_method=PATCH`, dd);


        console.log(res.data);
        console.log(res.status);

        return res.data;

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null;
    }
}

export default CarPaymentForm;
