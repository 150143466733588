import { Button, Group, Image, Modal } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useModals } from "@mantine/modals";
import { IconTrash } from "@tabler/icons-react";
import { FunctionComponent } from "react";
import extractImagePath from "./utils";
import CLIENT from "./client/client";
import { notifications } from "@mantine/notifications";
import { AxiosError } from "axios";

// wraps an image with a delete-er 
interface DeleteAbleImageProps {
    deletionKeyEnum: string,
    deletionId: number,
    src: string, path?: string, onRemoved: (c: string) => void
}

const DeleteAbleImage: FunctionComponent<DeleteAbleImageProps> = ({ src, path, onRemoved, deletionId, deletionKeyEnum }: DeleteAbleImageProps) => {



    const [opened2, s] = useDisclosure(false);

    // confirm for delete
    const [opened, close] = useDisclosure();







    return (

        <>
            <Modal opened={opened2} onClose={s.close} title="معاينة" centered>
                <Image src={src} ></Image>
            </Modal>

            <Modal title="تاكيد الاجراء" opened={opened} onClose={close.close}>
                هل انت متاكد من القيام بهذا الاجراء
                <Group mt="lg" justify="flex-end">
                    <Button onClick={() => close.close()} variant="default">
                        الغاء
                    </Button>
                    <Button onClick={async () => {
                        close.close();
                        await deleteImageOnServer(deletionId, deletionKeyEnum, (extractImagePath(src) ?? path)!);

                        onRemoved(src);

                        notifications.show({
                            message: "تم الحذف اعد تحميل العنصر لرؤية التعديلات الجديدة"
                        })
                    }} color="red"
                    >
                        نعم متاكد
                    </Button>
                </Group>
            </Modal>

            <div style={{ position: "relative" }}>
                <Button pos={"absolute"} color="red" variant="filled" m={8} onClick={close.open}>
                    <IconTrash size={16} />
                </Button>
                <img onClick={s.open} src={src} alt={"Image Placer"} height={400} />

            </div >


        </>

    );
}


async function deleteImageOnServer(deletionId: number, deletionKeyEnum: string, path: string) {
    try {
        await CLIENT().post(
            "/car/remove-images/" + deletionId.toString(),
            {
                key_enum: deletionKeyEnum,
                path_str: path
            }
        );
    } catch (e) {
        console.warn((e as any).response.data);
        console.warn((e as any).body);
        console.warn((e as any).data);

        alert(e);
        alert("حدث خطآ اعد تحميل الصفحة");
        throw e;
    }

}

export default DeleteAbleImage;