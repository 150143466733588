

import axios, { Axios } from 'axios';
import { retrieveTokenFromLocalStorage } from '../auth/AuthenticationPrivuder';

axios.defaults.baseURL = ((window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") ? 'http://33.33.33.99:8001' : 'https://alnojoombackend-production-c5d4.up.railway.app') + '/api/v1';

axios.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        //place your reentry code
    }
    return error;
});


function CLIENT(token?: string): Axios {

    return axios.create({
        headers: {
            common: {
                'Authorization': 'Bearer ' + (token ?? retrieveTokenFromLocalStorage()),
            }
        }
    });
};

export default CLIENT;
