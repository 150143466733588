import { Button, FileInput, Flex, Group, Image, Loader, Modal, ScrollArea, Space, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { IconPaperclip, IconPlus } from "@tabler/icons-react";
import { FunctionComponent, useEffect, useState } from "react";
import CLIENT from "../../core/client/client";



interface AddMoreFilesButtonProps {

    onClose: (selectedFiles: File[] | undefined) => void,
    forField: ImageDatabaseKeys,
    id: number,
    acceptedFormats?: string,
    maxAmount?: number
    label?: string,
}
export type ImageDatabaseKeys = "warehouse_images" | "loading_images" | "arrival_images";

const AddMoreFilesButton: FunctionComponent<AddMoreFilesButtonProps> = ({ forField, id, maxAmount, acceptedFormats, onClose, label }) => {
    const [opened, { open, close }] = useDisclosure(false);
    const [value, setValue] = useState<File[]>([]);
    const [loading, setLoading] = useState(false);


    useEffect(() => {

    }, [value]);

    return (
        <>
            <Modal opened={opened} onClose={() => {
                close();
                setValue([]);
            }} title="اضافة و رفع ملفات" dir="rtl" >
                <Text> سيتم رفع هذه الملفات على حدة و اضافتها للعنصر</Text>
                <Space h="lg" />
                <FileInput
                    value={value}
                    onChange={(e) => {
                        if (Array.isArray(e)) {
                            setValue(e);
                        } else {
                            if (e !== undefined && e !== null) { setValue([e]); }
                            else {
                                setValue([]);
                            }
                        }
                    }}
                    multiple={maxAmount === 1 ? false : true}
                    accept={acceptedFormats ?? "image/png,image/jpeg,image/jpg"}
                    label="الملفات للرفع"
                    placeholder={"يرجى اختيار الملف/الملفات للاضافة"}
                    size="md"
                />
                <Group mt="lg">
                    <Button disabled={loading || value.length === 0} onClick={async () => {
                        setLoading(true);
                        try {
                            await uploadAndThrowIfException(value, id, forField);
                            notifications.show({
                                message: "تم الرفع، اعد تحميل العنصر لرؤية الاضافات الجديدة"
                            })

                            close(); onClose(value);


                        } catch (e) {
                            alert("حدث خطا\n" + e);
                            console.log(e);
                        }

                        setLoading(false);
                    }}>
                        {loading ? <Loader /> : "تاكيد و رفع"}
                    </Button>
                    <Button variant="outline" disabled={loading} onClick={() => {
                        close(); onClose(undefined);

                    }}>الغاء</Button>

                </Group>

                {value.length !== 0 && !loading &&
                    <ScrollArea>
                        <Space h={"sm"} />
                        {/* <Flex direction={"row"} gap={"sm"}> */}
                        {
                            // value.map(e => <Image height={100} width={100} src={e as any} />)
                            value.map(e => <Text > {e.name + " | " + (e.size / (1024 * 1024)).toFixed(2) + " MB"}</Text>)
                        }
                        {/* </Flex> */}
                    </ScrollArea>
                }

            </Modal>



            <Button variant="light" onClick={open}>
                {label ?? "رفع المزيد"}
                <Space w={"xs"} />

                <IconPlus size={16} />

            </Button>

        </>
    );
}

async function uploadAndThrowIfException(files: File[], record_id: number, key_enum: ImageDatabaseKeys) {
    const c = CLIENT();

    const dd = {
        _method: 'POST',
        key_enum: key_enum,
        images: files,
    };
    console.warn(dd);

    const res = await c.postForm(`/car/add-images/${record_id}?_method=POST`, dd);


    console.log(res.data);
    console.log(res.status);
}

export default AddMoreFilesButton;