import { Autocomplete, Button, FileInput, Flex, Group, Image, Loader, Modal, Progress, RingProgress, Select, Space, Text, TextInput, Textarea } from "@mantine/core";
import { FunctionComponent, useState } from "react";
import { CarGeneralInfo } from "./interface";
import CustomerField from "../../../components/CustomerField";
import { DateInput, getFormattedDate } from "@mantine/dates";
import { formatDate } from "../../../core/format_date";
import CLIENT from "../../../core/client/client";
import CENTER_STYLE from "../../../core/CenterStyle";
import delay from "../../../core/delay_task";
import ImagesStats, { config2 } from "../../../core/ImagesStats";
import 'react-image-picker-editor/dist/index.css'

import ReactImagePickerEditor from "react-image-picker-editor";
import CarFormProps from "./CarFormProps";
import CopyToClipBoard from "../../../components/CopyToClipBoard";
import AddMoreFilesButton from "../../../components/forms/AddMoreFilesButton";
import DeleteAbleImage from "../../../core/DeleteAbleImage";
import checkUnAuthenticated from "../../../core/check_un_authenticated";
import { AxiosError } from "axios";
import { useAuth } from "../../../core/auth/AuthenticationPrivuder";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import path from "path";
import extractImagePath from "../../../core/utils";

interface CarGeneralInfoFormProps extends CarFormProps { }

const CarGeneralInfoForm: FunctionComponent<CarGeneralInfoFormProps> = ({ initial, close, setShouldReload, readOnly }) => {

    const [car, setCar] = useState<CarGeneralInfo>(initial ?? {} as CarGeneralInfo);

    const [loading, setLoading] = useState(false);
    const [warehouse_images, setWarehouse_images] = useState<File[]>([]);
    const [loading_images, setLoading_images] = useState<File[]>([]);

    const [file, files] = useState<Array<any>>();

    console.log(car);

    const [didFormChange, setDidFormChange] = useState<boolean>(false);

    const auth = useAuth();

    const [openedConfirmDelete, confirmDelete] = useDisclosure();




    return <form
        onChange={(e) => setDidFormChange(true)}
        onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            initial == undefined ?
                await saveNewCar(car, warehouse_images, loading_images)
                : await updateCar(car, warehouse_images, loading_images);
            setLoading(false);

            setShouldReload(true);

            /// sets the form to initial not changed
            setDidFormChange(false)

            // closes ONLY when submit was AN ADDITION POST CALL
            if (initial == undefined) close(true);
        }}>
        {loading ? <div style={CENTER_STYLE}>
            <Loader />
        </div> : <Flex direction={'column'} gap={'md'} aria-readonly={readOnly}>
            <Modal title="تاكيد الاجراء" opened={openedConfirmDelete} onClose={confirmDelete.close}>
                هل انت متاكد من القيام بهذا الاجراء لا تقم بحذف السيارات الا في الحالات الضرورية لانها قد تقوم بعم اخطاء مؤقتة لدى المستخدمين
                <Group mt="lg" justify="flex-end">
                    <Button onClick={() => confirmDelete.close()} variant="default">
                        الغاء
                    </Button>
                    <Button onClick={async () => {
                        confirmDelete.close();
                        setLoading(true);
                        const res = await deleteCarForever(car!.id!, auth);


                        if (res == null) {
                            setLoading(false);
                            alert("لا يمكنك حذف هذا العنصر لانه معتمد عليه في اماكن اخرى في قاعدة البيانات، العناصر القديمة تتكدس في اسفل التصنيف ما لم يتم تعديلها و سيذهب و يتارشف بعد فترة")
                            return;
                        }


                        setShouldReload(true);




                        notifications.show({
                            message: "تم الحذف"
                        });

                        notifications.clean();

                        close(true);

                        notifications.cleanQueue();
                        notifications.clean();
                    }} color="red"
                    >
                        نعم متاكد
                    </Button>
                </Group>
            </Modal>

            <Space h={'md'} />

            <TextInput
                readOnly={readOnly}
                required
                label='اسم السيارة و الموديل' placeholder="اسم السيارة و الموديل"
                value={car.car_name_and_model}
                onChange={(e) => setCar({
                    ...car,
                    car_name_and_model: e.currentTarget.value
                })}
            />

            <Flex direction={'row'} gap={'md'}>
                <TextInput
                    required
                    readOnly={readOnly}
                    flex={5}
                    label='الشاصي' placeholder='الشاصي'
                    value={car.vin}
                    rightSection={car.vin && <CopyToClipBoard data={car.vin} />}

                    onChange={(e) => setCar({
                        ...car,
                        vin: e.currentTarget.value
                    })}
                />
                <TextInput
                    required
                    readOnly={readOnly}
                    flex={3}
                    label='اللوت' placeholder='اللوت'
                    rightSection={car.lot && <CopyToClipBoard data={car.lot} />}

                    value={car.lot}
                    onChange={(e) => setCar({
                        ...car,
                        lot: e.currentTarget.value
                    })}
                />
            </Flex>

            <TextInput
                readOnly={readOnly}
                label='رابط تتبع الشحن' placeholder="رابط تتبع الشحن"
                value={car.shipment_follow_link}
                onChange={(e) => setCar({
                    ...car,
                    shipment_follow_link: e.currentTarget.value
                })}
            />

            <Group gap={'md'} grow>
                <TextInput
                    required
                    readOnly={readOnly}
                    label='المزاد' placeholder='المزاد'
                    value={car.auction}
                    onChange={(e) => setCar({
                        ...car,
                        auction: e.currentTarget.value
                    })}
                />
                <TextInput
                    required
                    readOnly={readOnly}
                    label='الباير' placeholder='الباير'
                    value={car.buyer}
                    onChange={(e) => setCar({
                        ...car,
                        buyer: e.currentTarget.value
                    })}
                />
            </Group>


            <Group gap={'md'} grow>
                <Autocomplete
                    readOnly={readOnly}
                    label='الوجهة' placeholder='العقبة, جبل علي, ام قصر'
                    value={car.destination}
                    limit={3}

                    data={
                        (['العقبة', 'ام قصر', 'جبل علي', 'Al-Aqaba', 'Al Aqaba', 'Aqaba', 'al aqaba', 'ألعقبة', 'العقبه', 'ألعقبه', 'mount ali', 'Mount Ali', 'Mount-Ali', 'mount-ali', "جبل العلي", 'um-qaser', 'Om Qaser', 'Om Qasar', 'أم قصر'])
                    }

                    onChange={(e) => setCar({
                        ...car,
                        destination: e?.trim()
                    })}
                />
                <TextInput
                    required
                    readOnly={readOnly}
                    label='الموقع' placeholder='الموقع'
                    value={car.region}
                    onChange={(e) => setCar({
                        ...car,
                        region: e.currentTarget.value
                    })}
                />
                <TextInput
                    required
                    readOnly={readOnly}
                    label='الولاية' placeholder='الولاية'
                    value={car.state}
                    onChange={(e) => setCar({
                        ...car,
                        state: e.currentTarget.value
                    })}
                />
            </Group>

            <CustomerField
                required
                initial={car.customer == undefined ? undefined : {
                    id: car.customer!.id,
                    name: car.customer!.name,

                }}
                readOnly={initial != null || readOnly}
                onSelected={(e) => setCar({
                    ...car,
                    customer_id: e?.id,
                })} />



            <Group gap={'md'} grow>
                <DateInput
                    readOnly={readOnly}
                    label='تاريخ الشراء' placeholder='تاريخ الشراء'
                    value={car.date_bought == undefined ? undefined : new Date(Date.parse(car.date_bought!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_bought: formatDate(e!),
                    })}
                />
                <DateInput
                    label='تاريخ السحب' placeholder='تاريخ السحب'
                    value={car.date_pulled == undefined ? undefined : new Date(car.date_pulled!)}
                    onChange={(e) => setCar({
                        ...car,
                        date_pulled: formatDate(e!),
                    })}
                />
                <DateInput
                    readOnly={readOnly}
                    label='تاريخ المستودع' placeholder='تاريخ المستودع'
                    value={car.date_warehouse == undefined ? undefined : new Date(Date.parse(car.date_warehouse!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_warehouse: formatDate(e!),
                    })}
                />

            </Group>



            <ImagesStats amount={car.warehouse_images?.length ?? 0} title={"صور المستــــودع"} >
                {/* ADD IMAGE SELECTION + INCLUDE THE READ ONLY DIRECTIVE */}

                {
                    (car.warehouse_images as any)?.map((e: any) => <DeleteAbleImage

                        deletionId={car.id!}
                        deletionKeyEnum="warehouse_images"

                        src={e}

                        onRemoved={
                            (c) =>
                                setCar({
                                    ...car,
                                    warehouse_images: (car.warehouse_images)?.filter((e) => e != c),
                                })
                        }


                    />)
                }

            </ImagesStats>


            {/* // edit add images on edit */}
            {(initial == undefined || car.warehouse_images?.length == 0 || car.warehouse_images == undefined)
                ? <FileInput accept="image/png,image/jpeg" clearable multiple value={warehouse_images} onChange={setWarehouse_images}
                    label="اختيار صور المستودع"
                /> :
                (initial != undefined && <AddMoreFilesButton
                    id={initial.id!}
                    forField="warehouse_images"
                    onClose={(e) => {
                        console.log("GET IMAGES");
                        console.log(e);
                    }}
                />)
            }



            <Flex direction={'row'} gap={'md'}>
                <Select
                    readOnly={readOnly}
                    flex={5}
                    data={['يوجد', 'لا يوجد']}
                    label='المفتاح' placeholder='المفتاح'
                    value={car.key_exists == undefined ? undefined : car.key_exists == true ? 'يوجد' : 'لا يوجد'}
                    onChange={(e) => setCar({
                        ...car,
                        key_exists: e == 'لا يوجد' ? false : true,
                    })}
                />
                <DateInput
                    readOnly={readOnly}
                    label='تاريخ الاوراق' placeholder='تاريخ الاوراق'
                    value={car.date_papers == undefined ? undefined : new Date(Date.parse(car.date_papers!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_papers: formatDate(e!),
                    })}
                />
            </Flex>

            <Group grow gap={'md'}>

                <TextInput
                    label='رقم الحاوية' placeholder='رقم الحاوية'
                    readOnly={readOnly}
                    value={car.container_number}
                    onChange={(e) => setCar({
                        ...car,
                        container_number: e.currentTarget.value
                    })}
                />

                <DateInput
                    readOnly={readOnly}
                    label='تاريخ التحميل' placeholder='تاريخ التحميل'
                    value={car.date_loaded == undefined ? undefined : new Date(Date.parse(car.date_loaded!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_loaded: formatDate(e!),
                    })}
                />
            </Group>

            {/* ADD IMAGE SELECTION */}


            <ImagesStats amount={car.loading_images?.length ?? 0} title={"صور التحميل"} >
                {/* ADD IMAGE SELECTION ++ READ ONLY FOR DELETEs */}

                {
                    (car.loading_images as any)?.map((e: any) =>

                        <DeleteAbleImage

                            deletionId={car.id!}
                            deletionKeyEnum="loading_images"

                            src={e}

                            onRemoved={
                                (c) =>
                                    setCar({
                                        ...car,
                                        loading_images: (car.loading_images)?.filter((e) => e != c),
                                    })
                            }


                        />
                    )
                }

            </ImagesStats>


            {/* // edit add images on edit */}
            {(initial == undefined || car.loading_images?.length == 0 || car.loading_images == undefined)
                ? <FileInput accept="image/png,image/jpeg" clearable multiple value={loading_images} onChange={setLoading_images}
                    label="اختيار صور التحميل"
                />
                :
                (initial != undefined && <AddMoreFilesButton
                    id={initial.id!}
                    forField="loading_images"
                    onClose={(e) => {
                        console.log("GET IMAGES");
                        console.log(e);
                    }}
                />)}



            <Group gap={'md'} grow>

                <DateInput
                    readOnly={readOnly}
                    label='تاريخ متوقع شحن ' placeholder='تاريخ متوقع شحن'
                    value={car.date_expected_shipped == undefined ? undefined : new Date(Date.parse(car.date_expected_shipped!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_expected_shipped: formatDate(e!),
                    })}
                />
                <DateInput
                    readOnly={readOnly}
                    label='تاريخ متوقع وصول' placeholder='تاريخ متوقع وصول'
                    value={car.date_expected_arrived == undefined ? undefined : new Date(Date.parse(car.date_expected_arrived!))}
                    onChange={(e) => setCar({
                        ...car,
                        date_expected_arrived: formatDate(e!),
                    })}
                />
            </Group>


            <Textarea
                label='ملاحظات' placeholder='ملاحظات'
                readOnly={readOnly}
                value={car.notes}
                onChange={(e) => setCar({
                    ...car,
                    notes: e.currentTarget.value
                })}

                autosize={true}
                minRows={2}
                maxRows={6}
            />

            <Space h={0.5} />

            <Flex direction={'row'} justify={'space-between'}>
                <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>

                {

                    (initial != undefined && auth.user.role == "manager") &&
                    <Button
                        color="red"
                        variant="light"
                        disabled={readOnly}
                        onClick={confirmDelete.open}
                    // disabled={initial != undefined && !didFormChange}
                    >{
                            "حذف نهائي"
                        }</Button>
                }
                <Button type="submit"
                    disabled={readOnly}
                // disabled={initial != undefined && !didFormChange}
                >{
                        initial == undefined ? "اضافة السيارة" : "حفظ التغييرات"
                    }</Button>




            </Flex>

            <Space h={'md'} />
        </Flex>}
    </form>;
}

async function deleteCarForever(id?: number, auth?: any) {
    try {
        const client = CLIENT();


        const res = await client.delete("/car/delete-car/" + id, {

        });



        return true;
    } catch (error: AxiosError | any) {
        checkUnAuthenticated(error, auth);

        return null;

    }
}


async function saveNewCar(car: CarGeneralInfo, f?: File[], fLoading?: File[]) {
    try {
        const c = CLIENT();

        console.warn(car);

        const res = await c.postForm('/car/general-info', {
            ...car,
            warehouse_files: f,
            loading_files: fLoading,
        });


        console.log(res.data);
        console.log(res.status);

    } catch (e) {
        console.error((e as any).response.data);
        alert("حدث خطا")
    }
}

// todo: add images on edit
async function updateCar(car: CarGeneralInfo, f: File[], fLoading?: File[]) {
    try {
        const c = CLIENT();

        console.warn(car);

        const res = await c.postForm(`/car/general-info/${car.id!}?_method=PATCH`, {
            ...car,
            // prevent reset hell 
            warehouse_files: car.warehouse_images?.length == 0 || car.warehouse_images == undefined ? f : undefined,
            loading_files: car.loading_images?.length == 0 || car.loading_images == undefined ? fLoading : undefined,
            warehouse_images: undefined,
            loading_images: undefined,
            key_exists: car.key_exists == true ? 1 : 0,
            // warehouse_files: f,
        });


        console.log(res.data);
        console.log(res.status);

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")
    }
}

export default CarGeneralInfoForm;