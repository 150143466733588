import { Button, FileInput, Flex, Group, Loader, Select, Space, TextInput, Textarea, Text, NumberInput, Combobox, ComboboxOption } from "@mantine/core";
import { FunctionComponent, ReactNode, useEffect, useState } from "react";
import { CarGeneralInfo, CarShipmentPayData } from "./interface";
import { DateInput } from "@mantine/dates";
import CustomerField from "../../../components/CustomerField";
import CENTER_STYLE from "../../../core/CenterStyle";
import ImagesStats from "../../../core/ImagesStats";
import { formatDate } from "../../../core/format_date";
import CLIENT from "../../../core/client/client";
import { notifications } from "@mantine/notifications";
import CarFormProps from "./CarFormProps";

interface CarShipmentPayFormProps extends CarFormProps {

}

const CarShipmentPayForm: FunctionComponent<CarShipmentPayFormProps> = ({ initial, close, setShouldReload }) => {
    const [car, setCar] = useState<CarShipmentPayData>({} as CarShipmentPayData);

    const [loading, setLoading] = useState(true);
    const [didFormChange, setDidFormChange] = useState<boolean>(false);
    const [warehouse_images, setWarehouse_images] = useState<File[]>([]);
    const [loading_images, setLoading_images] = useState<File[]>([]);

    const [file, files] = useState<Array<any>>();

    console.log(car);

    const DOLLARS = <Text size='xs' pr={10}>دولار امريكي</Text>;
    const RIGHT_SEC_W = 86;

    useEffect(initial === undefined ? () => { } : () => {
        loadCarShipmentInfo(initial!.id!).then(e => {
            if (e == null) {
                //     notifications.show({
                //         color: "red",
                //         title: "حدث خطأ",
                //         message: 'لم تنجح العملية، تحقق من اتصالك بالانترنت',
                //     });
                return;
            }

            setCar(e!);
            setLoading(false);
        });
    }, []);


    return <form
        onChange={(e) => setDidFormChange(true)}
        onSubmit={async (e) => {
            e.preventDefault();
            setLoading(true);
            const failed = await updateCarShipmentPayInfo(car);

            setLoading(false);


            if (failed == null) {
                // todo: integrate notification system
                // notifications.show({
                //     color: "red",
                //     title: "حدث خطأ",
                //     message: "تحقق من المعلومات المرسلة او اتصالك بالانترنت و اعد المحاولة",
                // });
                return;
            }

            setShouldReload(true);


            /// prevents save until further changes 
            setDidFormChange(false)

            // closes ONLY when submit was AN ADDITION POST CALL
            if (initial == undefined) close();
        }}>
        {loading ? <div style={CENTER_STYLE}>
            <Loader />
        </div> : <Flex direction={'column'} gap={'md'}>
            <Space h={'md'} />

            <Group grow>
                <NumberInput
                    label='شحن السيارة' placeholder='شحن السيارة'
                    value={car.car_shipment}
                    rightSection={DOLLARS}
                    rightSectionWidth={RIGHT_SEC_W}
                    onChange={(e) => setCar({
                        ...car,
                        car_shipment: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

                <NumberInput
                    label='الكمرك و الضريبة' placeholder='الكمرك و الضريبة'
                    value={car.import_taxes}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        import_taxes: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
            </Group>

            {/* <NumberInput
                label='' placeholder=''
                value={car.car_shipment}
                rightSection={DOLLARS}
                onChange={(e) => setCar({
                    ...car,
                    car_shipment: typeof e === 'number' ? e : Number.parseFloat(e)
                })}
            /> */}




            <Group grow>
                <NumberInput
                    label='سيارة كبيرة' placeholder='سيارة كبيرة'
                    value={car.big_car}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        big_car: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <NumberInput
                    label='هايبرد' placeholder='هايبرد'
                    value={car.hybrid}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        hybrid: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <NumberInput
                    label='بروكر' placeholder='بروكر'
                    value={car.broker}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        broker: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

            </Group>

            <Group grow>

                <NumberInput
                    label='اضافي نقل داخلي' placeholder='اضافي نقل داخلي'
                    value={car.additional_local_transport}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        additional_local_transport: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <NumberInput
                    label='رسوم جبل علي' placeholder='رسوم جبل علي'
                    value={car.mount_ali_fees}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        mount_ali_fees: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

            </Group>

            <Group grow>
                <NumberInput
                    label='تكاليف اخرى' placeholder='تكاليف اخرى'
                    value={car.other_costs}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        other_costs: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />
                <NumberInput
                    label='فاتورة الشحن الكلية' placeholder='فاتورة الشحن الكلية'
                    value={car.total_shipment_fee}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        total_shipment_fee: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

            </Group>


            <Group grow>
                <NumberInput
                    label='مبلغ الشحن المستلم' placeholder='مبلغ الشحن المستلم'
                    value={car.money_shipment_received}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        money_shipment_received: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />

                <NumberInput
                    label='المتبقي' placeholder='المتبقي'
                    value={car.money_owed_left}
                    rightSection={DOLLARS} rightSectionWidth={RIGHT_SEC_W}

                    onChange={(e) => setCar({
                        ...car,
                        money_owed_left: typeof e === 'number' ? e : Number.parseFloat(e)
                    })}
                />


            </Group>


            <Select

                data={[
                    { value: 'paid', label: 'مســـــدد' },
                    { value: 'unpaid', label: 'غير مسدد' },
                ]}
                label='حالة التسديد' placeholder='حالة التسديد'
                value={
                    car.status === undefined ? undefined : car.status
                }

                styles={
                    {
                        input: {
                            color: car.status === undefined ? undefined : car.status == "unpaid" ? "#F76707" : "#37B24D"
                        }
                    }
                }
                onChange={(_value, e) => {
                    if (e == null) return;
                    setDidFormChange(true);

                    setCar({
                        ...car,
                        status: e.value ?? undefined,
                    })
                }}
            />



            <Textarea
                label='ملاحظات' placeholder='ملاحظات'

                value={car.notes}
                onChange={(e) => setCar({
                    ...car,
                    notes: e.currentTarget.value
                })}

                autosize={true}
                minRows={2}
                maxRows={6}
            />

            <Space h={0.5} />

            <Flex direction={'row'} justify={'space-between'}>
                <Button onClick={() => close()} variant={'subtle'}>اغلاق و عدم الحفظ</Button>
                <Button type="submit"

                //disabled={!didFormChange} 

                >{
                        "حفظ التغييرات"
                    }</Button>

            </Flex>

            <Space h={'md'} />
        </Flex>
        }
    </form >;
}


async function loadCarShipmentInfo(id: number): Promise<CarShipmentPayData | null> {
    try {
        const c = CLIENT();

        console.warn(id);

        const res = await c.get(`/car/shipment-pay`, {
            params: {
                id: id,
            }
        });


        console.log(res.data);
        console.log(res.status);

        return res.data.data[0];

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null
    }
}

async function updateCarShipmentPayInfo(car: CarShipmentPayData): Promise<any | null> {
    try {
        const c = CLIENT();

        console.warn(car);

        const res = await c.patch(`/car/shipment-pay/${car.id!}`, {

            ...car,
            car_info_id: undefined,
        });


        console.log(res.data);
        console.log(res.status);

        return res.data;

    } catch (e) {
        console.error((e as any)?.response?.data);
        alert((e as any)?.response?.data?.message ?? "حدث خطا")

        return null;
    }
}



export default CarShipmentPayForm;