import { ActionIcon, Button, Center, Drawer, Flex, Group, Pagination, Select, Space, Table } from '@mantine/core';
import { fakerAR } from '@faker-js/faker';
import { MainLayout } from '../../layout/MainLayout';
import dayjs from "dayjs";
import { IconAdjustmentsHorizontal, IconFilter } from '@tabler/icons-react';
import { useDisclosure } from '@mantine/hooks';
import { useAuth } from '../../core/auth/AuthenticationPrivuder';
import SectionHead from '../../components/SectionHead';
import { useEffect, useState } from 'react';
import CLIENT from '../../core/client/client';
import CarFilter, { CarFilterForm } from './CarFilterationForm';
import CarDataManagementView from './car_data/CarDataManagementView';
import { Axios, AxiosError } from 'axios';
import { CarGeneralInfo } from './car_data/interface';
import PaginatedResponse from '../../core/paginated_response';
import checkUnAuthenticated from '../../core/check_un_authenticated';
import FiltersRow from '../../components/ FiltersRow';
import { formatDate } from '../../core/format_date';




const DTAE_FORMAT = "YYYY-MM-DD";

interface CarData {
    name: string;
    vin: string;
    lot: string;
    boughtDate: Date;
    region: string;
    customer_name: string;
    auction_paid_date: Date;
}

export function createRandomCar(): CarData {
    return {
        name: fakerAR.person.fullName(),
        vin: fakerAR.number.int({ min: 1, max: 1000 }).toString(),
        lot: fakerAR.number.int({ min: 1, max: 1000 }).toString(),
        boughtDate: fakerAR.date.anytime(),
        region: fakerAR.location.country(),
        customer_name: fakerAR.person.fullName(),
        auction_paid_date: fakerAR.date.anytime(),
    };
}

export const CARS: CarData[] = fakerAR.helpers.multiple(createRandomCar, {
    count: 5,
});


type CarStatusFilter =
    | 'paid'
    | 'unpaid'
    | 'canceled'
    | 'paid_s'
    | 'unpaid_s'
    | 'delivering'
    | 'delivered'
    | 'received';


function AllCarsListing() {
    const [addCarOpen, { open, close }] = useDisclosure(false);
    const [filterCarOpen, filterSetDialog] = useDisclosure(false);
    const [appliedFilters, setFilters] = useState<CarFilter
        | undefined>(undefined);
    const auth = useAuth();

    const [loading, setLoading] = useState(true);
    const [shouldReload, setShouldReload] = useState(false);

    const [currentCars, setCars] = useState<PaginatedResponse<CarGeneralInfo> | null>(null);

    /// used when each addCarOpen is used to determine what to load on the dialog drawer
    const [selectedCar, setSelectedCar] = useState<CarGeneralInfo | null>(null);

    function onLoad(withPage?: number, filter?: CarFilter, reset?: boolean) {
        setLoading(true)

        loadAllCars(withPage, reset ? undefined : filter ?? appliedFilters
            , statusesFilter
            , auth).then(e => {
                setCars(e)
                console.log(e, appliedFilters);
                setLoading(false)
            });
    }

    function selectAndOpenCar(c: CarGeneralInfo) {
        setSelectedCar(c);
        setShouldReload(false);
        open();
    }

    const [statusesFilter, setStatusesFilter] = useState<CarStatusFilter | undefined>();

    useEffect(onLoad, [statusesFilter]);

    return (

        <MainLayout loading={loading}>
            <Drawer
                size={'lg'}
                opened={addCarOpen} onClose={() => {
                    setSelectedCar(null);

                    close();

                    if (shouldReload) {
                        onLoad();
                        setShouldReload(false);
                    }

                }}
            >
                <CarDataManagementView setShouldReload={setShouldReload} initialGeneral={selectedCar ?? undefined} close={(doesReload) => {
                    close();

                    if (shouldReload || doesReload) {
                        onLoad();
                        setShouldReload(false);
                        setSelectedCar(null);
                    }
                }

                } />
            </Drawer>

            <Drawer opened={filterCarOpen} onClose={filterSetDialog.close} title="فلترة السيارات"  >
                <CarFilterForm
                    close={filterSetDialog.close}
                    onSubmit={(s) => {
                        console.log(s);
                        filterSetDialog.close();
                        setFilters(s);
                        // will reload by state assigned filter AND reset pagination
                        console.log(s);
                        onLoad(undefined, s, s === undefined);
                    }
                    } />
            </Drawer>

            {/* HEAD */}
            <SectionHead title={'أدارة السيارات'} onFilter={filterSetDialog.open} filtering={appliedFilters != undefined}>
                <Select
                    styles={
                        {
                            input: {
                                color: statusesFilter === undefined ? undefined : statusesFilter == "unpaid" ? "#F76707" : statusesFilter == 'canceled' ? "#F03E3E" : "#37B24D"
                            }
                        }
                    }
                    value={statusesFilter}
                    onChange={(v, s) => setStatusesFilter(typeof v === "string" ? v as CarStatusFilter : undefined)}
                    data={[
                        {
                            group: 'التسديد', items: [


                                { value: 'paid', label: 'مســـــدد' },
                                { value: 'unpaid', label: 'غير مسدد' },
                                { value: 'canceled', label: 'مـكنــسـلـــة' },

                            ]
                        },
                        {
                            group: 'الشحن', items: [
                                { value: 'paid_s', label: 'مسدد' },
                                { value: 'unpaid_s', label: 'غير مسدد' },
                            ]
                        },
                        {
                            group: 'الوصول', items: [
                                { value: 'delivering', label: 'قيــــد التــوصــيـــل' },
                                { value: 'delivered', label: 'تـــم الـوصـــول' },
                                { value: 'received', label: 'تـم التسـلـيـــم' },
                            ]
                        },
                    ]}
                    placeholder="حالة السيارة"

                    maw={172}
                    clearable
                    allowDeselect

                />
                <Button variant='outline' onClick={
                    () => onLoad()
                }>
                    أعادة التحميل
                </Button>
                {auth.user.role == 'manager' && <Button onClick={
                    () => {
                        open()
                    }
                }>
                    اضافة سيارة
                </Button>}
            </SectionHead>

            <Flex direction={'row'} justify={'start'}>
                <FiltersRow content={[
                    ...appliedFilters?.from == undefined ? [] : [{ title: 'من التاريخ', data: formatDate(appliedFilters.from) }],
                    ...appliedFilters?.to == undefined ? [] : [{ title: 'حتى التاريخ', data: formatDate(appliedFilters.to) }],
                    ...appliedFilters?.vin == undefined ? [] : [{ title: 'الشاصي', data: (appliedFilters.vin) }],
                    ...appliedFilters?.lot == undefined ? [] : [{ title: 'اللوت', data: (appliedFilters.lot) }],
                    ...appliedFilters?.car_name_and_model == undefined ? [] : [{ title: 'الأسم', data: (appliedFilters.car_name_and_model) }],
                    ...appliedFilters?.customer == undefined ? [] : [{ title: 'للزبون بمعرف', data: appliedFilters.customer }],
                ]} />
            </Flex>


            {/* <Space h="sm" /> */}


            {/* TABLE */}
            <Table>
                <Table.Thead>
                    <Table.Tr>
                        <Table.Th>اسم السيارة</Table.Th>
                        <Table.Th>VIN</Table.Th>
                        <Table.Th>LOT</Table.Th>
                        <Table.Th>تاريخ الشراء</Table.Th>
                        <Table.Th>المنطقة</Table.Th>
                        <Table.Th>الزبون</Table.Th>
                        <Table.Th>تاريخ الدفع للمزاد</Table.Th>
                    </Table.Tr>
                </Table.Thead>
                <Table.Tbody>{
                    currentCars?.data?.map((element) => (
                        <Table.Tr key={element.car_name_and_model} onClick={() => selectAndOpenCar(element)} >
                            <Table.Td maw={224}>{element.car_name_and_model}</Table.Td>
                            <Table.Td>{element.vin}</Table.Td>
                            <Table.Td>{element.lot}</Table.Td>
                            <Table.Td>{dayjs(element.date_bought?.toString() ?? "").format(DTAE_FORMAT)}</Table.Td>
                            <Table.Td>{element.region}</Table.Td>
                            <Table.Td>{element?.customer?.name}</Table.Td>
                            <Table.Td>{dayjs(element.date_paid_to_action?.toString() ?? "").format(DTAE_FORMAT)}</Table.Td>
                        </Table.Tr>
                    ))}</Table.Tbody>
            </Table>

            <Space h={'lg'}></Space>

            <Center>
                <Pagination

                    total={currentCars?.last_page ?? 1} siblings={1} defaultValue={currentCars?.current_page} onChange={
                        (e) => onLoad(e)
                    } />
            </Center>

            <Space h={'lg'}></Space>


        </MainLayout >
    );
}


async function loadAllCars(withPage?: number, filter?: CarFilter, statusFilter?: CarStatusFilter | undefined, auth?: any): Promise<PaginatedResponse<CarGeneralInfo> | null> {
    try {
        const client = CLIENT();


        const res = await client.get("/car/general-info", {
            params: {
                ...withPage ? { page: withPage } : {},
                ...filter,
                'car_status_filter': statusFilter,
            }
        });

        console.log(res.data.data[0]);

        return {
            data: res.data.data,
            ...res.data.meta,
        };
    } catch (error: AxiosError | any) {
        checkUnAuthenticated(error, auth);

        return null;

    }
}





export default AllCarsListing;


