export default function extractImagePath(url: string) {
    try {
        // Remove query parameters
        const cleanUrl = url.split("?")[0];

        // Extract path after the bucket domain
        const pathMatch = cleanUrl.match(/\.com\/(.+)/);

        if (pathMatch) {
            let formattedPath = pathMatch[1];

            // Decode URL encoding (e.g., "%20" → " ")
            formattedPath = decodeURIComponent(formattedPath);

            // Ensure slashes are properly escaped
            formattedPath = formattedPath.replace(/\//g, "\\/");

            return formattedPath;
        }

        return null;
    } catch (error) {
        console.error("Error extracting image path:", error);
        return null;
    }
}


// // Example Usage:
// const url = "https://alnojoombucket.s3.eu-north-1.amazonaws.com/uploads/1737196208_Simulator%20Screenshot%20-%20iPhone%2015%20-%202024-04-24%20at%2000.12.15.png?X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAZQ3DRUFFGPYHGDWH%2F20250212%2Feu-north-1%2Fs3%2Faws4_request&X-Amz-Date=20250212T152908Z&X-Amz-SignedHeaders=host&X-Amz-Expires=1800&X-Amz-Signature=8bc4e53b8e995eb0623f0d5db0e6776a20f492adb33413e12a49c7058c41dc92";

// console.log(extractImagePath(url));
// // Output: "uploads\/1737196208_Simulator Screenshot - iPhone 15 - 2024-04-24 at 00.12.15.png"
